<template>
  <label class="ciam-radio">
    <input 
      type="radio" 
      :value="value"
      :name="name"
      :checked="modelValue === value"
      @change="onChange"
      class="ciam-radio-input"
    >
    <span class="ciam-radio-label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'ciam-radio',
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    name: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
      this.$emit('update:model-value', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.ciam-radio {
  @apply flex items-center space-x-2 cursor-pointer;
}

.ciam-radio-input {
  @apply h-4 w-4 text-indigo-600 transition duration-150 ease-in-out;

  &:focus {
    @apply outline-none ring-2 ring-offset-2 ring-indigo-500;
  }
}

.ciam-radio-label {
  @apply text-sm text-gray-700;
}
</style>
