<template>
    <div>
        <ciam-card>
            <ciam-card-header>
                <template v-slot:header><a :href="anchor">
                        <ciam-icon class="text-gray-400 h-4 w-4 cursor-pointer" name="fa-link"></ciam-icon>
                    </a>{{ $t('deployment.configuration.upgradeOnDemand.title') }}</template>
                <template v-slot:subtitle>{{ $t('deployment.configuration.upgradeOnDemand.subtitle') }}</template>
            </ciam-card-header>
            <ciam-card-content>
                <ciam-card-content-lines>
                    <ciam-card-content-line type="split-content-component">
                        <template v-slot:label> {{ $t(`deployment.configuration.upgradeOnDemand.title`) }} </template>

                        <template v-slot:content>
                            <div>
                                <div class="documentation"></div>
                                <div class="action">
                                    <ciam-button v-if="deploymentStatus === 'RUNNING' && isUpgradeAvailable()"
                                        @click="showUpgradeDialog()" class="secondary w-max">
                                        
                                        {{ $t(`deployment.configuration.upgradeOnDemand.button`, {
                                            version: upgrades.upgrades[0].target.split('keycloak-x:')[1] || "the latest minor version"
                                        })}}
                                        <template v-slot:right>
                                            <ciam-icon name="fa-upload"></ciam-icon>
                                        </template>
                                    </ciam-button>
                                    <!-- case upgrade available but status not running -->
                                    <ciam-button v-else-if="isUpgradeAvailable()" class="disabled animate-pulse"
                                        disabled>
                                        {{ $t(`deployment.configuration.upgradeOnDemand.button`, {
                                            version: upgrades.upgrades[0].target.split('keycloak-x:')[1] || "the latest minor version"
                                        })}}
                                        <template v-slot:right>
                                            <ciam-icon name="fa-upload"></ciam-icon>
                                        </template>
                                    </ciam-button>
                                    <!-- upgrade not available -->
                                    <ciam-button v-else class="disabled animate-pulse" disabled>
                                        {{ $t(`deployment.configuration.upgradeOnDemand.buttonNotAvailable`) }}
                                        <template v-slot:right>
                                            <ciam-icon name="fa-upload"></ciam-icon>
                                        </template>
                                    </ciam-button>
                                </div>
                            </div>
                        </template>
                    </ciam-card-content-line>
                </ciam-card-content-lines>
            </ciam-card-content>
        </ciam-card>

        <!-- Upgrade Dialog -->
        <ciam-confirm-modal
            :model-value="showDialog"
            @update:model-value="showDialog = $event"
            :confirm-text="$t('actions.confirm')"
            :cancel-text="$t('actions.cancel')"
            @result="handleDialogResult"
        >
            <template v-slot:header>
                {{ $t('deployment.configuration.upgradeOnDemand.dialog.title') }}
            </template>
            <template v-slot:default>
                <ciam-card-content-lines>
                    <ciam-card-content-line type="split-content-component">
                        <template v-slot:label>
                            {{ $t('deployment.configuration.upgradeOnDemand.dialog.description') }}
                        </template>
                        <template v-slot:content>
                            <div class="flex space-x-12">
                                <ciam-radio
                                    :model-value="selectedStatus"
                                    @update:model-value="selectedStatus = $event"
                                    :value="MaintenanceTiming.NOW"
                                    name="upgrade-status"
                                >
                                    {{ $t('deployment.configuration.upgradeOnDemand.dialog.asap') }}
                                </ciam-radio>
                                <ciam-radio
                                    :model-value="selectedStatus"
                                    @update:model-value="selectedStatus = $event"
                                    :value="MaintenanceTiming.NEXT_WINDOW"
                                    name="upgrade-status"
                                >
                                    {{ $t('deployment.configuration.upgradeOnDemand.dialog.pending') }}
                                </ciam-radio>
                            </div>
                        </template>
                    </ciam-card-content-line>
                </ciam-card-content-lines>
            </template>
        </ciam-confirm-modal>
    </div>
</template>

<script>
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCardContentLines from '@/components/CiamCardContentLines';
import DeploymentService from '@/pages/deployments/DeploymentService';
import { Notification } from 'vue-notifyjs';
import CiamConfirmModal from '@/components/CiamConfirmModal';
import CiamRadio from '@/components/CiamRadio';
import { MaintenanceTiming } from '@/enums/MaintenanceTiming';

export default {
    name: 'UpgradeOnDemand',
    components: {
        CiamCard,
        CiamCardHeader,
        CiamCardContentLines,
        CiamConfirmModal,
        CiamRadio,
    },
    props: {
        deploymentId: {
            type: String,
        },
        anchor: {
            type: String,
            required: true
        },
        deploymentStatus: {
            type: String
        }  
    },
    data() {
        return {
            problem: null,
            upgrades: { upgrades: [] },
            showDialog: false,
            selectedStatus: MaintenanceTiming.NOW,
            MaintenanceTiming,
        };
    },
    methods: {
        showUpgradeDialog() {
            this.showDialog = true;
        },
        async handleDialogResult(confirmed) {
            if (confirmed) {
                await this.launchMaintenance();
            }
            this.showDialog = false;
        },
        async launchMaintenance() {
            try {
                await DeploymentService.upgradeDeployment(this.deploymentId, this.selectedStatus);
                Notification.notify({
                    message: 'Upgrade has been scheduled...',
                    type: 'info',
                });
            } catch (err) {
                console.error(err);
                Notification.notify({
                    message: 'Could not start upgrade: ' + err,
                    type: 'danger',
                });
            }
        },
        isUpgradeAvailable() {
            return this.upgrades.upgrades && this.upgrades.upgrades.length > 0;
        }
    },
    async created() {
        this.upgrades = await DeploymentService.isUpgradeAvailable(this.deploymentId);
    }
};
</script>
